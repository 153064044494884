import { Injectable, EventEmitter, Output } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class cursosServices {
  URL_BACKEND = environment.api_url;

  constructor(private http: HttpClient) {}

  getCursos(): Observable<any> {
    let token   = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });

    return this.http.get<any>( `${environment.api_url}user/get_cursos`, { headers: headers } );
  }

  getCursosPorSegmento(segmentoCodigo: string): Observable<any> {
    let token   = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(
      `${environment.api_url}courses/get_cursos_por_segmento/${segmentoCodigo}`,
      { headers: headers }
    );
  }

  getSegmentos(): Observable<any> {
    let token   = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(
      `${environment.api_url}segments/get_segmentos`,
      { headers: headers }
    );
  }

  getCursosWeb(token: any, idcurso: any): Observable<any> {

    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Authorization' : token
    });
    let params = {
      idCurso : idcurso
    }
    return this.http.get<any>(
    `${environment.api_url}cursos/`,
      { headers: headers, params: params }
    );
  }
}
