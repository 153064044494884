  <!-- Agregar un selector para filtrar los cursos por segmento -->
<div class="relative flex items-center mx-10 mt-4">
  <label for="segmentSelect" class="mr-4 text-white font-medium">
    Filtrar por:
  </label>
  <select
    id="segmentSelect"
    #segmentSelect
    (change)="filtrarPorSegmento($event)"
    class="w-full max-w-sm border border-gray-300 rounded-lg bg-white px-4 py-2 text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
  >
    <option value="all">Seleccionar Nivel</option>
    <option *ngFor="let segmento of segmentos" [value]="segmento.code">{{ segmento.nombre }}</option>
  </select>
</div>

<div id="content-list" class="content-list">
  <ul
    class="list-course px-[40px] pt-[30px] grid grid-cols-1 sm:grid-cols-2 gap-[35px] lg:grid-cols-3 lg:gap-[50px]">
    <li *ngFor="let curso of dataCurso | paginate : { itemsPerPage: numItemsAmostrar, currentPage: paginaActual } ; let i=index" class="item-course">
      <div class="date-course text-[#C4C4C4] text-[14px] leading-[160%] md:text-[12px]">
        <!-- 12 AGO 2022 -->
        {{ formatDate(curso.fechaCreacion)  }}
      </div>
      <!--  <div class="title-course text-[18px] text-white mt-[10px] mb-[20px]">
        {{ curso.subtitulo }}
      </div> -->
      <!-- <div class="curso" (click)="entraCurso(curso.idCurso)" style="cursor: pointer">
        <div class="titulo_curso w-full">
          <h4>{{ curso.nombreCurso }}</h4>
        </div> -->
        <!-- <div
          class="bg-cover bg-no-repeat min-h-[185px] relative"
          [ngStyle]="{
            'background-image': 'url(' + curso.thumbnailRutaPublica + ')',
            'min-width': '35%'
          }">
          <a href="#" class="absolute right-[20px] bottom-[15px]">
            <img
              class="w-[58px] md:w-[38px]"
              src="../../../assets/icons/play.png"
              alt="play"/>
          </a>
        </div> -->
<!--         <div class="bg-cover bg-no-repeat min-h-[185px] relative" [ngStyle]="{
          'background-image': 'url(' + curso.img + ')',
          'background-position': 'right',
          'min-width': '35%'
          }" >
          <img (click)="entraCurso(curso.idCurso)" class="absolute right-[20px] bottom-[15px] w-[58px] md:w-[38px]" src="../../../assets/icons/play.png" alt="play"/>
        </div>
      </div> -->
      <!-- MODIFICACION DE LA PARTE DE LA IMAGEN  -->
      <div class="curso relative" (click)="entraCurso(curso.idCurso)" style="cursor: pointer">
        <img src="{{curso.img}}" alt="{{curso.nombreCurso}}" class="w-full">
        <img (click)="entraCurso(curso.idCurso)" class="absolute right-[20px] bottom-[15px] w-[58px] md:w-[38px]" src="../../../assets/icons/play.png" alt="play"/>
      </div>
      <div
        class="description-course text-[18px] text-white leading-[160%] mt-[20px] text-justify line-clamp-4 md:text-[14px] color">
        <app-show-more [curso]="curso"></app-show-more>
      </div>
      <div class="inscription mt-[30px]" (click)="entraCurso(curso.idCurso)" style="cursor: pointer">
        <a *ngIf="token == null || token == undefined" class="btn-inscription-course rounded-[10px] flex max-w-[225px] py-[11px] items-center justify-center text-[20px] text-[#FF0000] border-[#FF0000] border-[1px] hover:bg-[#FF0000] hover:text-white md:text-[14px] md:max-w-[175px]">
          Inscríbete aquí
          <i class="fa fa-angle-right ml-[20px]"></i>
        </a>
      </div>
    </li>
  </ul>
  <div
    class="flex px-[40px] py-[40px] justify-between items-center md:max-w-[330px] md:mx-auto">
    <pagination-controls (pageChange)="paginaActual = $event" (pageChange)="scrollToElement()" previousLabel="" nextLabel=""> </pagination-controls>

    <!-- <a href="#"><img class="w-[27px]" src="../../../assets/icons/arrow-left.png" alt="anterior" /></a>
    <ul class="page-numbers flex text-white text-[20px]">
      <li class="ml-[15px] mr-[15px]">
        <span class="page-numbers current">1</span>
      </li>
      <li class="ml-[15px] mr-[15px]">
        <a href="#" class="page-numbers">2</a>
      </li>
      <li class="ml-[15px] mr-[15px]">
        <a href="#" class="page-numbers">3</a>
      </li>
    </ul>
    <a href="#"><img class="w-[27px]" src="../../../assets/icons/arrow-right.png" alt="siguiente" /></a> -->
  </div>
</div>

<!-- <div *ngIf="modalLogin == true">
  <app-login></app-login>
</div>
 -->
